import React from "react";
import JumboSplash from "./Splash/JumboSplash";
import styled from "styled-components";
import Icons from "./Icons/Icons";
import { Container, Row, Col, Card } from "react-bootstrap";
import { ScribiNavLink } from "./Scribi-Components/ScribiComponents";

const FeatureDisplayContainer = styled(Container)`
  padding: 4rem 0;
  color: #060606;

  h2 {
    margin-bottom: 3rem;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .feature-section {
    margin-bottom: 4rem;
  }

  .feature-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 3rem;
    color: #0e4a73;
    margin-bottom: 1rem;
  }

  .feature-card {
    border: none;
    background-color: #f9f9f9;
    text-align: center;
    height: 100%;
    p {
      margin-bottom: 0.5rem;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  .feature-card .card-body {
    padding: 2rem 1rem;
  }

  .card-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }

  .card-text {
    font-size: 1rem;
    color: #555;
  }
`;

const ButtonBase = styled(ScribiNavLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
`;

const PrimaryButton = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5e7eb;
  color: #0e4a73;
  border: none;
  width: auto;
  &:hover {
    background-color: #e5e7eb;
  }
`;

const Roadmap = () => {
  return (
    <>
      <JumboSplash />
      <FeatureDisplayContainer>
        <h2>The Road Ahead</h2>
        <p style={{ maxWidth: "800px", margin: "0 auto", textAlign: "center", fontSize: "1.1rem", color: "#555", marginBottom: "2rem" }}>
          Scribi is designed for long-term growth. Its modular foundation allows features to be added without disrupting existing functionality. Here's what’s coming next:
        </p>
        <div className="feature-section">
          <Row className="g-2">
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.Productivity />
                  </div>
                  <Card.Title>Productivity Tracking</Card.Title>
                  <Card.Text>
                    Scribi tracks your writing sessions, stores the data securely, and helps you identify your most productive times of day. Future updates will let you generate smart writing schedules, connect with calendar apps, and optionally receive reminders via a mobile companion.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.Network />
                  </div>
                  <Card.Title>Scribi Plus</Card.Title>
                  <Card.Text>
                    Scribi’s core features will always be available with a one-time license. Scribi Plus ($15/month) will enable cross-device syncing, live collaboration, and access to mobile companion apps.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.Language />
                  </div>
                  <Card.Title>Conlang Support</Card.Title>
                  <Card.Text>
                    Build your own constructed languages using a grammar-based system that feeds into Scribi's AI assistant. Translate text, grow your dictionary, and automate conlang workflows for immersive worldbuilding.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.Pipeline />
                  </div>
                  <Card.Title>Publishing Pipelines</Card.Title>
                  <Card.Text>
                    Future updates aim to connect Scribi directly to publishing platforms like Amazon, Kobo, and D2D. Edit your backmatter once, and push updates across your catalog with a single click. This functionality is scaffolded and waiting on external API partnerships.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.BookAtlas />
                  </div>
                  <Card.Title>Author Wikis & Landing Pages</Card.Title>
                  <Card.Text>
                    Generate wikis, character databases, or conlang guides directly from your Scribi data. Future features will let you publish and customize public-facing content and integrate directly with newsletters and social media.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.Shapes />
                  </div>
                  <Card.Title>Social & Newsletter Templates</Card.Title>
                  <Card.Text>
                    Use Scribi's template engine to create and schedule content for newsletters and social media. Integrations are planned for platforms like Mailchimp, Facebook, and Instagram. Save time by centralizing content creation.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <div className="feature-icon">
                    <Icons.FileDashedLine />
                  </div>
                  <Card.Title>Editor Format Presets</Card.Title>
                  <Card.Text>
                    Switch the editor into different formats like screenplay, stage play, or interactive fiction. Presets ensure correct formatting and make it easier to export for eBook or print. Additional formats like TTRPG books are planned.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <p style={{ maxWidth: "800px", margin: "0 auto", textAlign: "center", fontSize: "1.1rem", color: "#555" }}>
          Excited for Scribi? Don’t forget you get a 14-day free trial, and you can split your purchase over 12 months.
        </p>
        <PrimaryButton to="/download">Get Scribi!</PrimaryButton>
      </FeatureDisplayContainer>
    </>
  );
};

export default Roadmap;