import React from "react";
import {
  BodyContainer,
  HrDivider,
  ScribiNavLink,
} from "../Scribi-Components/ScribiComponents";

const TOC = () => {
  return (
    <BodyContainer dark>
      <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
        <h2><strong>Terms and Conditions</strong></h2>

        <p>Welcome to Scribi Writer's Studio.</p>

        <p>
          TLDR: Be a good Scribi citizen. Don’t break the law, don’t abuse the platform, and respect your own and others' intellectual property. You own what you create, and you're responsible for how it's used. We use cookies only to improve the site experience and manage authentication—nothing shady. Any questions? Reach out. We're here to help.
        </p>

        <HrDivider />

        <p>
          These Terms and Conditions govern your use of Scribi Writer’s Studio, a desktop software developed and distributed by Scribi, LLC, including any associated cloud services available through https://scribi.app.
        </p>

        <p>
          By using the software or accessing any connected services, you agree to these terms. If you do not accept these terms in full, you must not use Scribi.
        </p>

        <h3><strong>Cookies</strong></h3>

        <p>
          We use cookies on our website to enhance user experience, manage authentication, and provide access to account-related services. For details, please see our <ScribiNavLink to="/privacy">Privacy Policy</ScribiNavLink>.
        </p>

        <h3><strong>License and Restrictions</strong></h3>

        <p>
          Unless otherwise stated, Scribi, LLC owns the intellectual property rights for the Scribi Writer’s Studio software and all related materials. All rights are reserved. You may use the software for your personal, lawful, creative projects, but you may not:
        </p>

        <ul>
          <li>Republish or redistribute the software or its components</li>
          <li>Sell, rent, or sublicense the software</li>
          <li>Reverse engineer, decompile, or disassemble the software</li>
          <li>Use the software in any way that violates applicable laws</li>
        </ul>

        <h3><strong>AI Assistant and LLM Usage</strong></h3>

        <p>
          Scribi Writer's Studio includes an AI assistant powered by large language models (LLMs) accessed via OpenRouter. Scribi does not train, own, or control these models, and cannot guarantee the safety, accuracy, or behavior of their output.
        </p>

        <p>By using the AI assistant, you acknowledge that:</p>
        <ul>
          <li>You are solely responsible for all content generated, edited, or submitted through the AI assistant.</li>
          <li>You may not use the AI assistant to generate or store illegal, harmful, or abusive content.</li>
          <li>You may insert your own prompts or modify system behavior, but Scribi is not responsible for the results.</li>
          <li>Scribi does not monitor your private AI usage but may comply with legal subpoenas for user activity or data.</li>
        </ul>

        <h3><strong>Content Storage and User Responsibility</strong></h3>

        <p>
          Your content is stored locally on your device and optionally backed up to Scribi's cloud infrastructure. You retain all intellectual property rights to the content you create, and Scribi claims no ownership.
        </p>

        <p>
          You are fully responsible for your stored data and any content shared or published through Scribi. You agree not to use the platform to create, store, or transmit illegal content.
        </p>

        <p>
          Scribi, LLC reserves the right to restrict or terminate access to cloud services for users who violate these terms. We also reserve the right to comply with any valid legal order, subpoena, or investigation.
        </p>

        <h3><strong>Liability Disclaimer</strong></h3>

        <p>
          To the fullest extent permitted by law, Scribi, LLC disclaims all warranties, express or implied, regarding the performance, reliability, or fitness of Scribi Writer’s Studio.
        </p>

        <p>
          Scribi is not liable for any data loss, corruption, or failure caused by third-party providers, natural disasters, user error, or technical limitations. Users are encouraged to maintain local backups of critical content.
        </p>

        <h3><strong>Changes to These Terms</strong></h3>

        <p>
          Scribi, LLC reserves the right to modify these terms at any time. Continued use of Scribi Writer's Studio constitutes acceptance of any changes.
        </p>

        <p>
          If you have any questions about these Terms and Conditions, please contact us at support@scribi.app.
        </p>
      </div>
    </BodyContainer>
  );
};

export default TOC;