import React, { useState } from "react";
import { AppHeader, HeaderLink, IconButton } from "../Scribi-Components/ScribiComponents";
import Icons from "../Icons/Icons";
import { Scribi } from "../utilities/api/store/store";
import { getAuth, signOut } from "firebase/auth";
import Offcanvas from "react-bootstrap/Offcanvas";

const Header = () => {
    const { user } = React.useContext(Scribi);
    const [showMenu, setShowMenu] = useState(false);

    const handleShowMenu = () => setShowMenu(true);
    const handleCloseMenu = () => setShowMenu(false);

    return (
        <>
            <AppHeader>
                <HeaderLink to="/">Home</HeaderLink>
                <HeaderLink to="/download">Pricing</HeaderLink>
                <HeaderLink to="/roadmap">Feature Roadmap</HeaderLink>
                {user && <HeaderLink to="/account" onClick={handleCloseMenu}>Account</HeaderLink>}
                {!user && <HeaderLink to="/login">Login/Signup</HeaderLink>}
                {user && <HeaderLink to="/" onClick={async () => {
                    try {
                        let auth = getAuth();
                        await signOut(auth);
                    } catch {
                        console.log("Error logging out");
                    }
                }}>Logout</HeaderLink>}
                <IconButton className={"mobile-menu-button"} onClick={handleShowMenu}>
                    <Icons.MenuIcon />
                </IconButton>
            </AppHeader>

            <Offcanvas show={showMenu} onHide={handleCloseMenu} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
                        <HeaderLink to="/" onClick={handleCloseMenu}>Home</HeaderLink>
                        <HeaderLink to="/download" onClick={handleCloseMenu}>Purchase</HeaderLink>
                        <HeaderLink to="/roadmap" onClick={handleCloseMenu}>Feature Roadmap</HeaderLink>
                        {user && <HeaderLink to="/account" onClick={handleCloseMenu}>Account</HeaderLink>}
                        {!user && <HeaderLink to="/login" onClick={handleCloseMenu}>Login/Signup</HeaderLink>}
                        {user && <HeaderLink to="/" onClick={async () => {
                            try {
                                let auth = getAuth();
                                await signOut(auth);
                                handleCloseMenu();
                            } catch {
                                console.log("Error logging out");
                            }
                        }}>Logout</HeaderLink>}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}

export default Header;