import React, { useEffect, useState } from "react";
import JumboSplash from "./Splash/JumboSplash";
import styled from "styled-components";
import Icons from "./Icons/Icons";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const FeatureDisplayContainer = styled(Container)`
  padding: 4rem 0;
  color: #060606;

  h2 {
    margin-bottom: 3rem;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .feature-section {
    margin-bottom: 4rem;
  }

  .feature-icon {
    font-size: 3rem;
    color: #0e4a73;
    margin-bottom: 1rem;
  }

  .feature-card {
    border: none;
    background-color: #f9f9f9;
    text-align: center;
    height: 100%;
  }

  .feature-card .card-body {
    padding: 2rem 1rem;
  }

  .card-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }

  .card-text {
    font-size: 1rem;
    color: #555;
  }
`;

const LearnButton = styled(Button)`
  background-color: #e5e7eb;
  color: #0e4a73;
  border: none;
  padding: 12px 24px;
  font-weight: 600;
  transition: all 0.2s ease;
  margin-top: 2rem;

  &:hover {
    background-color: #0e4a73;
    color: #e5e7eb;
  }
`;

const Home = () => {
  const [openFeature, setOpenFeature] = useState(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const features = [
    {
      id: 1,
      icon: <Icons.Brain />,
      title: `End-to-End Writing Support`,
      description: `From ideas to a finished book, Scribi supports every step—brainstorming, outlining, drafting, editing, and publishing—without ever needing to switch platforms.`
    },
    {
      id: 2,
      icon: <Icons.FileExport />,
      title: `Flexible Export Options`,
      description: `Easily format and export as PDF, ePub, DOCX, or RTF. Re-import edits or your entire catalog in seconds.`
    },
    {
      id: 3,
      icon: <Icons.Book />,
      title: `Innovative Book Block System`,
      description: `Keep your front/back matter templates consistent across your catalog. Update calls to action or newsletter links instantly.`
    },
    {
      id: 4,
      icon: <Icons.Shapes />,
      title: `Powerful Templating`,
      description: `Create templates for outlines, manuscripts, and beat sheets. Tailor them by genre, series, or pen name.`
    },
    {
      id: 5,
      icon: <Icons.Database />,
      title: `Integrated Storybase`,
      description: `Track characters, locations, and world-building elements in a searchable database linked to your manuscript.`
    },
    {
      id: 6,
      icon: <Icons.PuzzlePiece />,
      title: `Modular Workspace`,
      description: `Customize your interface. Hide modules you don’t need and focus on your preferred workflow.`
    },
    {
      id: 7,
      icon: <Icons.AiBot />,
      title: `Built-in AI Assistant`,
      description: `Scribi’s integrated AI assistant helps with character creation, scene planning, manuscript analysis, and more—all with natural, intuitive interaction. Optimized for efficiency, fully customizable, and easy to set up with your own OpenRouter API key.`
    },
    {
      id: 8,
      icon: <Icons.Tools />,
      title: `Constantly Evolving`,
      description: `Features like ConLang support, publishing pipelines, and social integrations are actively in development. Scribi improves every week.`
    }
  ];

  useEffect(() => {
    setOpenFeature(null);
  }, [isMobile]);

  return (
    <>
      <JumboSplash />
      <FeatureDisplayContainer>
        <h2>Your All-in-One Writing Solution</h2>

        <div className="feature-section">
          <h3 className="text-center mb-5">Key Features</h3>
          <Row className="g-4">
            {features.map((feature) => (
              <Col md={4} key={feature.id}>
                <FeatureCard
                  id={feature.id}
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                  openFeature={openFeature}
                  setOpenFeature={setOpenFeature}
                />
              </Col>
            ))}
          </Row>
        </div>

        <div className="feature-section">
          <h3 className="text-center mb-4">Why Choose Scribi?</h3>
          <p style={{ maxWidth: "800px", margin: "0 auto", textAlign: "center", fontSize: "1.1rem", color: "#555" }}>
            Built by an author for authors, Scribi streamlines every part of your writing process. It combines the power of multiple apps into one smart, adaptable studio designed for how you actually work.
          </p>
        </div>

        <div className="feature-section">
          <h3 className="text-center mb-5">Who Can Benefit?</h3>
          <Row className="g-4">
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <Card.Title>Indie Authors</Card.Title>
                  <Card.Text>
                    Manage your catalog, maintain brand consistency, and produce professional-quality work faster. Scribi’s database tools help you track every detail in your story world.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <Card.Title>Aspiring Authors</Card.Title>
                  <Card.Text>
                    Access guided templates, tools, and resources that help you write with confidence, clarity, and structure. Learn the craft as you write.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <Card.Title>Traditional Authors</Card.Title>
                  <Card.Text>
                    Get insight into your manuscript and streamline collaboration with your editor or publisher. Scribi empowers your workflow while staying out of your way.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>

        <div className="feature-section" style={{ textAlign: "center" }}>
          <h3>Experience Writing Without Boundaries</h3>
          <p style={{ maxWidth: "800px", margin: "0 auto", fontSize: "1.1rem", color: "#555" }}>
            Focus on your story while Scribi handles the rest. From world-building to publication, it evolves with you.
          </p>
          <LearnButton href="/download">
            Sign Up to get Early Access to Scribi <Icons.MailingList />
          </LearnButton>
        </div>
      </FeatureDisplayContainer>
    </>
  );
};

export default Home;

const FeatureCard = ({ id, icon, title, description }) => {
  return (
    <Card className="feature-card h-100">
      <Card.Body>
        <div className="feature-icon">{icon}</div>
        <Card.Title>{title}</Card.Title>
        <Card.Text>{description}</Card.Text>
      </Card.Body>
    </Card>
  );
};
