import React from "react";
import JumboSplash from "./Splash/JumboSplash";
import styled from "styled-components";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
} from "react-bootstrap";
import { ScribiNavLink } from "./Scribi-Components/ScribiComponents";
import ScribiRestService from "./utilities/firestore/ScribiRestService";

const FeatureDisplayContainer = styled(Container)`
  padding: 4rem 0;
  color: #060606;

  h2 {
    margin-bottom: 3rem;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
  }
  h4 {
    margin-bottom: 3rem;
    text-align: center;
  }

  .feature-section {
    margin-bottom: 4rem;
  }

  .feature-icon {
    font-size: 3rem;
    color: #0e4a73;
    margin-bottom: 1rem;
  }

  .feature-card {
    border: none;
    background-color: #f9f9f9;
    text-align: center;
    height: 100%;
  }

  .feature-card .card-body {
    padding: 2rem 1rem;
  }

  .card-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-top: 0.5rem;
  }

  .card-text {
    font-size: 1rem;
    color: #555;
  }
`;

const ButtonBase = styled(ScribiNavLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
`;

const BuyButton = styled(ScribiNavLink)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 600;
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none;
  justify-content: center;
  background-color: #e5e7eb;
  color: #0e4a73;
  border: none;
  &:hover {
    background-color: #e5e7eb;
  }
`;

const PrimaryButton = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e5e7eb;
  color: #0e4a73;
  border: none;
  width: auto;
  &:hover {
    background-color: #e5e7eb;
  }
`;

const Download = () => {
  return (
    <>
      <JumboSplash />
      <FeatureDisplayContainer>
        <h2>Coming Soon!</h2>
        <h4>I am currently targeting a June 2025 release date!</h4>
        <p style={{ maxWidth: "800px", margin: "0 auto", marginBottom: "0.5rem", textAlign: "center", fontSize: "1.1rem", color: "#555" }}>
          Scribi is in its final development stages and will be available soon. You'll be able to download a free trial to explore its features. Once it's ready, you can purchase a lifetime license—no subscriptions, no upgrade fees, no platform limitations. Scribi is built for serious writers, by a writer. Future features will continue to grow the platform regardless of sales.
        </p>
        <p style={{ maxWidth: "800px", margin: "0 auto", textAlign: "center", fontSize: "1.1rem", color: "#555" }}>
          Scribi aims to be accessible to every writer. You can choose between a one-time payment or a monthly plan. The monthly plan is $12 for 12 months. Once paid off, the license is yours to keep. You can switch to a full purchase anytime—early payoffs reduce your remaining balance. If your monthly payments pause, your license will be suspended (you can still export your work, but not add new content) until payments resume.
        </p>

        <div className="feature-section">
          <Row className="g-2">
            <Col md={6}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <Card.Title>Buy for $129</Card.Title>
                  <Card.Text>
                    One-time payment. Lifetime license. Includes all future updates.
                  </Card.Text>
                </Card.Body>
                <Card.Footer style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <BuyButton disabled>Check Back Soon</BuyButton>
                </Card.Footer>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="feature-card h-100">
                <Card.Body>
                  <Card.Title>$12/month for 12 months</Card.Title>
                  <Card.Text>
                    A flexible payment option. After 12 payments, the license is yours forever. If paused, your license will be temporarily suspended.
                  </Card.Text>
                </Card.Body>
                <Card.Footer style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <BuyButton disabled>Check Back Soon</BuyButton>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </div>

        <p style={{ maxWidth: "800px", margin: "0 auto", textAlign: "center", fontSize: "1.1rem", color: "#555" }}>
          Want to see what's coming next? Explore the roadmap!
        </p>
        <PrimaryButton to="/roadmap">View the Roadmap</PrimaryButton>
      </FeatureDisplayContainer>
    </>
  );
};

export default Download;