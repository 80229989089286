import React from "react";
import {
  BodyContainer,
  HrDivider,
  ScribiLink,
  ScribiNavLink,
} from "../Scribi-Components/ScribiComponents";

const Policies = () => {
  return (
    <BodyContainer dark>
      <div style={{ maxWidth: "1000px", margin: "0 auto" }}>
        <h2><strong>Scribi's Privacy Policy</strong></h2>

        <p>
          TLDR: We don’t sell or share your data, we don’t track you, and we only collect what’s necessary to provide Scribi Writer’s Studio and its cloud services. You own your data.
        </p>

        <p>
          This Privacy Policy describes how Scribi, LLC collects, uses, and protects personal information associated with the use of Scribi Writer’s Studio and the Scribi website (https://scribi.app).
        </p>

        <HrDivider />

        <h3>Information Collection and Use</h3>
        <p>
          When you use Scribi, we collect only the information necessary to authenticate your account and provide service access. This may include your email address, name, and subscription status.
        </p>

        <p>
          If you sign in via Google OAuth, we may access your Google ID and related metadata to connect services like Google Drive or Calendar—but only if you opt in. Scribi does not store or analyze this data beyond what’s required to deliver the requested functionality.
        </p>

        <h3>AI Assistant and LLM Usage</h3>
        <p>
          The Scribi desktop app includes an AI assistant that connects to external large language models (LLMs) via OpenRouter. We do not collect, store, or analyze your private AI queries or chat history.
        </p>
        <p>
          You are responsible for any data entered into the AI assistant. While we provide system prompts and context features, we do not control the LLMs, nor do we accept liability for generated responses. Use of the AI assistant is subject to the same privacy expectations as other parts of Scribi.
        </p>

        <h3>Cookies</h3>
        <p>
          We use cookies only for login sessions and app functionality—never for marketing or tracking. You can decline cookies, but certain features (like staying logged in) may not work properly.
        </p>

        <h3>Log Data</h3>
        <p>
          We may log anonymized data such as IP address, browser version, and feature usage strictly for debugging and security. This data is never sold, shared, or used for marketing.
        </p>

        <h3>Data Storage and Backups</h3>
        <p>
          Your data is primarily stored locally on your machine. If you enable cloud backups, your encrypted data is stored using Google Cloud Storage and authenticated via Firebase.
        </p>
        <p>
          Scribi, LLC does not access your private writing or personal content unless legally required to do so (e.g., in response to a subpoena or legal investigation).
        </p>

        <h3>Third-Party Providers</h3>
        <p>
          We rely on a small number of third-party providers to deliver key services:
        </p>
        <ul>
          <li>
            <ScribiLink href="https://www.stripe.com/privacy">Stripe</ScribiLink> – Payment processing
          </li>
          <li>
            <ScribiLink href="https://policies.google.com/privacy">Google Cloud</ScribiLink> – Authentication, backups, and cloud services
          </li>
          <li>
            <ScribiLink href="https://openrouter.ai/privacy">OpenRouter</ScribiLink> – AI model routing for Scribi Assistant
          </li>
        </ul>

        <h3>Children’s Privacy</h3>
        <p>
          Scribi does not knowingly collect personal information from users under 13. If you are a parent or guardian and believe your child has used the app, contact us immediately.
        </p>

        <h3>Security</h3>
        <p>
          We follow industry best practices to secure all user data, including OAuth-protected logins and encrypted backups. However, no system is 100% secure. Users are encouraged to keep local copies of important work.
        </p>

        <h3>Changes to This Policy</h3>
        <p>
          We may update this Privacy Policy from time to time. When we do, we’ll post updates on this page. Continued use of the platform after changes constitutes agreement to the revised policy.
        </p>

        <h3>Contact</h3>
        <p>
          For questions or concerns about this Privacy Policy, email us at:
          <ScribiLink href="mailto:admin@scribi.app" style={{ color: "white" }}>admin@scribi.app</ScribiLink>
        </p>
      </div>
    </BodyContainer>
  );
};

export default Policies;
